// import dependencies
import React from "react";

// import components
import Layout from "../components/layout";
import Section from "../components/section"
import SeparatorTopGrey from "../components/separators/separator-top-grey";

// import styles and assets
import * as styles from "./styles/error-page.module.scss";

export default function NotFoundPage () {
    return (
        <Layout>
            <Section color={'white'}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className={styles.errorMessages}>
                                <h1>Error 404</h1>
                                <h1>¿Qué es ingeniosamente?</h1>
                                <h4>Ingeniosamente es una plataforma que cuenta con actividades, talleres y entretenidas notas en ciencia y tecnología con enfoque de género, para que explores y te aproximes a las STEM.</h4>
                                <h4>Queremos que tú seas la próxima líder en ciencia y tecnología, por eso te invitamos a inspirarte con Ingeniosamente.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <SeparatorTopGrey />
            </Section>
        </Layout>
    );
}